"use strict";

$('.all-faq-items').each((index, element) => {
    $(element).find('.faq-question').on('click', (event) => {
        const currentElement = $(event.currentTarget).closest('.faq-item');
        $(element).find('.faq-item').not(currentElement).removeClass('active').find('.faq-answer').slideUp();
        currentElement.toggleClass('active');
        currentElement.find('.faq-answer').slideToggle();
    });
});



// Horizontal number tap section
const checkHeight = () => {
    $('.horizontal-number-taps-wrap').each((index, element) => {
        let thisHorizontalTap = $(element);
        let horizontalheight = 0;
        thisHorizontalTap.find('.horizontal-number-tap-item').each((index, element) => {
            if($(element).hasClass('active')){
                horizontalheight = $(element).find('.horizontal-number-tap-content-area').height();
                $(element).css({
                    'padding-bottom': horizontalheight + 'px'
                });
            } else {
                setTimeout(() => {
                    $(element).css({
                        'padding-bottom': 0
                    });
                }, 250);
            }
        });
    });
}

checkHeight();

$('.horizontal-number-taps-wrap').each((index, element) => {
    $(element).find('.horizontal-number-tap-icon-area').on('click', (event) => {
        checkHeight();
    });
});