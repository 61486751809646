"use strict";

$('.all-faq-items').each((index, element) => {
    $(element).find('.faq-question').on('click', (event) => {
        const currentElement = $(event.currentTarget).closest('.faq-item');
        $(element).find('.faq-item').not(currentElement).removeClass('active').find('.faq-answer').slideUp();
        currentElement.toggleClass('active');
        currentElement.find('.faq-answer').slideToggle();
    });
});



